
html, body {
  height: 100%;
  width: 100%;
}

@keyframes wiggle {
  0% { transform: rotate(0deg);}
  50% { transform: rotate(10deg); }
  100% { transform: rotate(0deg);}
}

#jag {
  /*animation-name: wiggle;
  animation-duration: 1s;
  animation-iteration-count: 3;
  animation-delay: 1s;
  transform: rotate(0deg);*/
}

#root {
  transition: all 2s cubic-bezier(0.230, 1.000, 0.320, 1.000);
  transform: translateY(-50px);
  opacity: 0;
}

